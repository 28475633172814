import React, { useContext } from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { CustomerLogos, GetStarted, SectionWrapper } from "../components/sections";
import PortableText from "../components/portableText";
import { QuoteCardContent } from "../components/sections/quote-panels";
import { EyebrowPill } from "../components/serializers";
import { ImageBuilder } from "../components/global/image-builder";
import { CardContext } from "../components/card/context";
import { SectionContext } from "../components/sections/context";
import { pageDocPath } from "../../helpers";
import { typesMap } from "../components/sections/resources-list";
import { ArrowRight } from "../components/icon/arrow-left";
import { DARK_BACKGROUNDS } from "../components/global/layout";
import { cn, mapEdgesToNodes } from "../lib/helpers";
import { CarouselSlides } from "../components/sections/carousel-slides";
import { CardGrid } from "../components/card/card-grid";
import { SiteContext } from "../components/global/site-context";
import { Button } from "../components/button";
import { DEMO_PHRASE, DEMO_URL } from "../components/sections/snowflake/navigation";
import { VideoPopover } from "../components/video-popover";
import { PRODUCT_VIDEO_ID } from "../components/sections/snowflake/hero";
import { Promo } from "../components/sections/promo";
import { PillHeader } from "../components/sections/wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import * as styles from "../components/portableText.module.css";

export const query = graphql`
  query OfferingTemplateQuery($id: String!, $categories: [String!]) {
    doc: sanityOffering(id: { eq: $id }) {
      _type
      id
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      label
      header
      heroImage {
        ...SanityImage
      }
      quote {
        ... on SanityQuotable {
          ...Quotable
        }
        ... on SanityStory {
          ...cardNodeStory
        }
      }
      quotes {
        ... on SanityQuotable {
          ...Quotable
        }
        ... on SanityStory {
          ...cardNodeStory
        }
      }
      showSecurity
      promo {
        asset {
          id
          _type
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          file {
            asset {
              url
            }
          }
          mainImage {
            ...SanityImage
          }
          slug {
            current
          }
        }
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
      }
      _rawGetStartedSummary(resolveReferences: { maxDepth: 5 })
      categories {
        _id
        title
        titlePublic
        slug {
          current
        }
        status
      }
      problems {
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
        mainImage {
          ...SanityImage
        }
      }
      _rawSolutionsIntro(resolveReferences: { maxDepth: 5 })
      solutions {
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
        mainImage {
          ...SanityImage
        }
      }
      playbooks {
        ...cardNodePlaybook
      }
      backgroundColor
      replace {
        ... on SanityColumnsPortableText {
          _key
          header
          _rawContent(resolveReferences: { maxDepth: 5 })
          mainImage {
            ...SanityImageSimple
          }
          mainImageFade {
            ...SanityImageSimple
          }
          mainVideo {
            asset {
              url
            }
          }
          _rawContent(resolveReferences: { maxDepth: 5 })
          author {
            ...SanityAuthor
          }
          layout
          link
        }
      }
      # documentation
    }
    # logos: allSanityStory(
    #   limit: 6
    #   sort: { fields: [orderRank] }
    #   filter: {
    #     status: { eq: "published" }
    #     categories: {
    #       elemMatch: { _id: { in: $categories } }
    #     }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodeStory
    #     }
    #   }
    # }
    logos: allSanityCompany (
      limit: 6
      sort: { fields: [orderRank] }
      filter: {
        status: { eq: "published" }
        categories: {
          elemMatch: { _id: { in: $categories } }
        }
      }
    ) {
      edges {
        node {
          ...Company
        }
      }
    }
    recentTaggedPosts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: [DESC] }
      filter: { 
        slug: { current: { ne: null } },
        isPublished: { eq: true }
        categories: { elemMatch: { _id: { in: $categories } } }
    }) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    recentPosts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: [DESC] }
      filter: { 
        slug: { current: { ne: null } },
        isPublished: { eq: true }
    }) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const OfferingTemplate = ({ data, pageContext }) => (
  <LayoutContainer
    doc={{
      ...data.doc,
      backgroundColor: data.doc.backgroundColor || "lightGreen",
    }}
    hideNav={pageContext.mode === "hello"}
  >
    <PageHeadMeta title={data.doc.title || data.doc.header} doc={data.doc} />
    {data.doc &&
      <OfferingPage
        {...data.doc}
        pageContext={pageContext}
        posts={
          []
            .concat(mapEdgesToNodes(data.recentTaggedPosts))
            .concat(mapEdgesToNodes(data.recentPosts))
        }
        logos={mapEdgesToNodes(data.logos)}
        helloMode={pageContext.mode === "hello"}
      />
    }
  </LayoutContainer>
);

export default OfferingTemplate;

const BADGES = [
  {
    image: "https://cdn.sanity.io/images/vt347z5x/production/10a444f930aee29b1e389a7db86b1bd9b2a0ae56-370x370.png",
    text: "SOC2 compliance",
  },
  {
    image: "https://cdn.sanity.io/images/vt347z5x/production/ffd7ec37772694e549ee0b4f958dd2d04b104ecd-370x370.png",
    text: "GDPR compliance",
  },
  {
    image: "https://cdn.sanity.io/images/vt347z5x/production/c8b491bb4306ee07ab87a006f26ecfc6116715ff-371x370.png",
    text: "CCPA compliance",
  },
  {
    image: "https://cdn.sanity.io/images/vt347z5x/production/eaa753ded4174da8c4ee7572e9e43a2313b87dfe-370x370.png",
    text: <>SSO, SAML, <br /> and SCIM built in</>,
  },
  {
    image: "https://cdn.sanity.io/images/vt347z5x/production/600c103ea9dbc55a3b00095ff671659218a01298-370x370.png",
    text: <>Zero data retention <br /> for AI</>,
  },
];

const OfferingPage = ({
  _rawExcerpt,
  header,
  heroImage,
  quote,
  quotes,
  categories,
  problems,
  _rawSolutionsIntro,
  solutions,
  backgroundColor,
  playbooks,
  label,
  replace,
  pageContext,
  posts,
  showSecurity,
  _rawGetStartedSummary,
  logos,
  helloMode,
  promo,
}) => {
  const site = useContext(SiteContext);
  const displayQuote = quote ? quote?._type === "quotable" ? quote : quote.quote : null;
  const isDarkBg = DARK_BACKGROUNDS.includes(backgroundColor);
  const mainTag = categories?.[0];
  const tagTitle = ["published"].includes(mainTag?.status)
    ? (mainTag?.titlePublic || mainTag?.title).toLowerCase()
    : null;

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          name: "header",
          container: "narrow",
          padding: "top",
          layout: "centered",
        }}
      >
        <div className="flex flex-col items-center mt-8 md:mt-0">
          <EyebrowPill>{label}</EyebrowPill>
          <h1 className="text-4xl sm:text-6xl font-medium text-center">{header}</h1>
          <PortableText
            blocks={_rawExcerpt}
            className="text-xl leading-relaxed text-center mt-6"
          />
          {pageContext.mode === "hello" && (
            <div className="flex mt-6">
              <VideoPopover
                vimeoId={PRODUCT_VIDEO_ID}
                buttonText={site.isNotMobile ? "Watch 5-min demo" : "Watch demo"}
                buttonIcon={true}
                buttonBg="bg-transparent"
                buttonClass={cn("mr-3", isDarkBg ? "border-white text-white" : null)}
              />
              <Button
                text={DEMO_PHRASE}
                href={DEMO_URL}
                bgColor={isDarkBg ? "bg-white text-black" : "bg-black"}
                isBig={true}
              />
            </div>
          )}
        </div>
      </SectionWrapper>

      <SectionWrapper padding="bottom" layout="centered">
        <ImageBuilder image={heroImage} className="mt-12 rounded-xl mx-auto" />
      </SectionWrapper>

      <SectionWrapper header={site.token("offeringsResults")}>
        {quotes.length > 0 ? (
          <>
            <div className="md:grid md:grid-cols-3 gap-2 lg:gap-4 mb-4">
              {quotes.map((q, i) => (
                <QuoteLinkCard
                  href={q._type === "story" && !helloMode ? pageDocPath(q) : null}
                  quote={q?._type === "quotable" ? q : q.quote}
                  isDarkBg={isDarkBg}
                  key={i}
                />
              ))}
            </div>
            <SectionContext.Provider value={{ isDarkBg }}>
              <CustomerLogos
                nodes={logos}
                section={logos.length <= 0 ? { category: mainTag?.slug.current } : null}
              />
            </SectionContext.Provider>
          </>
        ) : (
          <div className="md:grid md:grid-cols-2/5-3/5 gap-2 lg:gap-4">
            <QuoteLinkCard
              quote={displayQuote}
              isDarkBg={isDarkBg}
              href={quote._type === "story" && !helloMode ? pageDocPath(quote) : null}
            />
            <SectionContext.Provider value={{ isColumn: true, isDarkBg }}>
              <CustomerLogos
                nodes={logos}
                section={logos.length <= 0 ? { category: mainTag?.slug.current } : null}
              />
            </SectionContext.Provider>
          </div>
        )}
      </SectionWrapper>

      {showSecurity && (
        <SectionWrapper header={site.token("offeringsSecurity")}>
          <div className="flex gap-12 justify-between items-end mb-8">
            <h4 className="text-6xl">
              Enterprise-grade security by design
            </h4>
            <div>
              <a
                href="/security/"
                className="text-link hover:text-black whitespace-nowrap font-medium"
                target={helloMode ? "_blank" : null}
              >
                Read more <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3 font-medium md:text-lg mb-2">
            {BADGES.map((badge, i) => (
              <div key={i} className="flex flex-col items-center bg-white p-2 pt-4 rounded-lg">
                <ImageBuilder src={badge.image} />
                <p className="text-center relative -top-4">{badge.text}</p>
              </div>
            ))}
          </div>
        </SectionWrapper>
      )}

      <SectionWrapper background="black" header={site.token("offeringsProblems")}>
        <ul className="grid md:grid-cols-3 gap-4">
          {problems.map((p, index) => (
            <li
              key={index}
              className="p-8 md:p-4 md:py-6 bg-gray-900 border border-light-10 rounded-xl flex flex-col justify-between items-center"
            >
              <ImageBuilder image={p.mainImage} className="mb-4" />
              <div className="md:min-h-32">
                <h3 className="font-medium mt-2 md:mt-0 text-2xl md:text-lg xl:text-xl mb-2">{p.header}</h3>
                <PortableText blocks={p._rawSummary} className="text-auxiliary leading-snug" />
              </div>
            </li>
          ))}
        </ul>
      </SectionWrapper>

      {replace.length > 0 && (
        <SectionWrapper padding="top" header={site.token("offeringsTools")}>
          <CarouselSlides section={{ content: replace, layout: "verticalTabs" }} />
        </SectionWrapper>
      )}

      <SectionWrapper header={site.token("offeringsBetter")}>
        <PortableText blocks={_rawSolutionsIntro} className="text-6xl mb-12 lg:w-3/5" />
        <ul className="flex flex-col max-w-screen-md lg:max-w-full">
          {solutions.map((s, index) => (
            <li
              key={index}
              className={cn(
                "rounded-xl mb-8 overflow-hidden flex flex-col lg:flex-row",
                "max-w-120 lg:max-w-full",
                isDarkBg ? "bg-light-5" : "bg-white",
                index % 2 === 0 ? null : "self-end"
              )}
            >
              <div className="p-8 flex flex-col justify-center">
                <h3 className="text-3xl xl:text-4xl max:text-5xl mb-8">{s.header}</h3>
                <PortableText
                  blocks={s._rawSummary}
                  className={cn(
                    "xl:text-xl xl:leading-normal",
                    styles.arrowLinks
                  )}
                />
              </div>
              <ImageBuilder image={s.mainImage} />
            </li>
          ))}
        </ul>
      </SectionWrapper>

      {/* <SectionContext.Provider value={{ isDarkBg: true }}>
        <SectionWrapper background="black"
          header={
            !playbooks && mainTag?.title
              ? `${site.token("playbooksPill")} for ${(
                mainTag?.titlePublic || mainTag?.title
              ).toLowerCase()}`
              : site.token("playbooksPill")}>
          <div>
            {playbooks ? (
              <CardGrid
                nodes={playbooks}
                maxColumns={3}
                browseMoreHref="/playbooks/"
                browseMoreText="View all playbooks"
                makeThumbnail={true}
                panels={true}
                cta={typesMap.get("playbook")?.cta}
                hideType={true}
                showSummary={true}
              />
            ) : (<PlaybooksBlock
              section={{ category: mainTag?.slug.current }}
            />)}
          </div>
        </SectionWrapper>
      </SectionContext.Provider > */}

      {promo?.asset && (
        <SectionWrapper>
          <a
            href={`/download/${promo?.asset?.slug?.current}/`}
            target="_blank"
            className="flex flex-col lg:flex-row items-center p-8 lg:p-12 py-24 lg:py-24 gap-8 lg:gap-24 mb-8 rounded-xl bg-cover bg-center"
            style={{
              backgroundImage: "url('https://cdn.sanity.io/images/vt347z5x/production/cc54aadfc518769b9de0dabb7972fa86926e3a18-2400x1040.png?auto=format')"
            }}
          >
            <ImageBuilder
              image={promo?.asset?.mainImage}
              width={400}
              className="rounded-xl border border-white shadow-lg transform transition-size scale-100 hover:scale-102 duration-200"
            />
            <div className="lg:order-first text-center lg:text-left sm:max-w-2/3">
              <h3 className="text-4xl">{promo?.header}</h3>
              <PortableText className="my-6" blocks={promo?._rawSummary} />
              <Button text="Download now for free" bgColor="bg-black" />
            </div>
          </a>
          {/* <Promo
          _rawOffer=""
          buttonText="Download"
          file=""
          externalUrl=""
          previewImage={promo.asset.mainImage.asset.url}
          formIntro={
            <div className="mt-2 mb-6">
              <h4 className="text-2xl font-medium mb-2">
                Master AI pipegen
              </h4>
              <p>Get the free enterprise guide</p>
            </div>
          }
        /> */}
        </SectionWrapper>
      )}

      <SectionWrapper background="mint">
        <GetStarted section={{ _rawSummary: _rawGetStartedSummary }} />
      </SectionWrapper>

      {!helloMode && (
        <SectionWrapper
          background="black"
          anchor="resources"
        >
          <div>
            <PillHeader
              // isDarkBg={true}
              // background="mint"
              header={
                tagTitle
                  ? `Learn more about ${tagTitle}`
                  : site.token("suggestions")
              }
              className="mb-6 lg:mb-10 bg-dark-50"
            />
            {/* <ResourcesSuggestions section={{ cards: 3, types: ["post"] }} /> */}
            <CardGrid
              nodes={playbooks.slice(0, 1).concat(posts).slice(0, 3)}
              maxColumns={3}
              browseMoreHref={`/resources/${tagTitle ? categories[0].slug.current : ""}`}
              browseMoreText={
                tagTitle
                  ? `All ${tagTitle} resources`
                  : "View all resources"
              }
              makeThumbnail={true}
              panels={true}
              showSummary={true}
            />
          </div>
        </SectionWrapper>
      )}
    </>
  );
};


const QuoteLinkCard = ({ quote, isDarkBg, href }) => (
  <a
    href={href}
    className={cn(
      "relative block rounded-lg p-4 lg:p-6 pt-0 mb-2 md:mb-0",
      isDarkBg ? "bg-light-5" : "bg-white",
      href ? "hover:shadow-lg" : null
    )}
  >
    <div>
      <CardContext.Provider value={{ authors: quote?.authors }}>
        <QuoteCardContent
          q={quote}
          showCardLogo={true}
          boldText={true}
        />
        {href && (
          <div className="mt-8">
            <span className="text-link font-medium">
              <ArrowRight className="mr-2" />
              {typesMap.get("story")?.cta}
            </span>
          </div>
        )}
      </CardContext.Provider>
    </div>
  </a>
);
